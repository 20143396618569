.main-container {
  min-height: 100vh; /* will cover the 100% of viewport */
  overflow: hidden;
  display: block;
  position: relative;
  padding-bottom: 250px;
}
@media only screen and (max-width: 800px) {
  .main-container {
    padding-bottom: 425px;
  }
}