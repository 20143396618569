.articleTitle-container {
  margin: 0;
}

.articleTitle-text {
  font-family: "SF Pro Display", serif;
  font-style: normal;
  font-weight: bold;
  font-size: 41px;
  line-height: 120%;
  letter-spacing: 0.2px;
  color: #09322e;
  width: 50%;
}
.articleTitle-img {
  margin-right: 50px;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("../../assets/fonts/SFProDisplay-Bold.eot");
  src: local("SF Pro Display Bold"), local("SFProDisplay-Bold"),
    url("../../assets/fonts/SFProDisplay-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/fonts/SFProDisplay-Bold.woff2") format("woff2"),
    url("../../assets/fonts/SFProDisplay-Bold.woff") format("woff"),
    url("../../assets/fonts/SFProDisplay-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@media only screen and (min-width: 801px) and (max-width: 1199px) {
  .articleTitle-bg-img {
    width: 100%;
  }
  .article-back-img {
    left: 2rem;
    top: 2rem;
  }
  .articleTitle-text {
    margin-top: 2rem;
  }
}

@media only screen and (max-width: 800px) {
  .articleTitle-text {
    font-size: 24px;
    width: 100%;
    margin-top: 1.5rem;
  }
  .articleTitle-bg-img {
    width: 100%;
    height: 191px;
    object-fit: cover;
  }
  .articleTitle-img {
    width: 30px;
    height: 30px;
  }
  .article-back-img {
    left: 1rem;
    top: 1rem;
  }
}
