.mainDesc-title {
    font-family: 'SF Pro Display', serif;
    font-weight: bold;
    font-size: 41px;
    line-height: 120%;
    letter-spacing: 0.2px;
    color: #002F34;
}
.mainDesc-text {
    font-family: 'SF Pro Display', serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    letter-spacing: 0.2px;
    color: #000000;
    white-space: pre-line;
}
.mainDesc-container {
    padding: 7.5rem 0;
}
.mainDesc-container .mainDesc-in-container {
    padding: 0;
}
@font-face {
    font-family: 'SF Pro Display';
    src: url('../../assets/fonts/SFProDisplay-Medium.eot');
    src: local('SF Pro Display Medium'), local('SFProDisplay-Medium'),
    url('../../assets/fonts/SFProDisplay-Medium.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/SFProDisplay-Medium.woff2') format('woff2'),
    url('../../assets/fonts/SFProDisplay-Medium.woff') format('woff'),
    url('../../assets/fonts/SFProDisplay-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('../../assets/fonts/SFProDisplay-Bold.eot');
    src: local('SF Pro Display Bold'), local('SFProDisplay-Bold'),
    url('../../assets/fonts/SFProDisplay-Bold.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/SFProDisplay-Bold.woff2') format('woff2'),
    url('../../assets/fonts/SFProDisplay-Bold.woff') format('woff'),
    url('../../assets/fonts/SFProDisplay-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@media only screen and (max-width: 800px) {
    .main-img {
        display: none;
    }
    .mainDesc-title {
        font-size: 24px;
    }
    .mainDesc-text {
        font-size: 14px;
    }
    .mainDesc-in-container {
        flex-direction: column;
    }
    .mainDesc-container {
        padding: 7% 0;
    }
    .mainDesc-container .mainDesc-in-container {
        padding: 1rem;
    }
}