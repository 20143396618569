.footer {
  background-color: #002f34;
  bottom: 0;
  width: 100%;
  position: absolute;
  padding: 3rem 0;
}
.column-link-first {
  display: flex;
}
.mob {
  flex-direction: row;
}

.footer-link {
  margin-bottom: 1rem;
}
.footer-link:last-child {
  margin-bottom: 0;
}
.column-link-first {
  margin-right: 4.5rem;
}

html,
body {
  height: 100%;
  position: relative;
}

.olx-text {
  font-size: 12px;
  font-family: "SF Pro Display";
  font-weight: 500;
  color: #dddddd ;
}

@media only screen and (max-width: 800px) {
  .footer-link:last-child {
    margin-bottom: 1rem;
  }
  .footer {
    padding-left: 7%;
    padding-right: 7%;
  }
  .column-link-first {
    /* display: none!important; */
    flex-direction: column;
  }
  .mob{
    flex-direction: column;
  }
  .mob-social-container {
    display: flex;
    justify-content: flex-end;
  }
  .olx-text {
    font-size: 12px;
    font-family: "SF Pro Display";
    font-weight: 500;
    color: #dddddd ;
  }
}
@media (min-width: 1200px) {

}