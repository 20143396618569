.cards-container {
  padding: 7.5rem 0;
  background-color: #eef5f5;
}
.card-container-slide-icons a:first-child {
  margin-right: 10px;
}
.rec-pagination {
  display: none!important;
}
.card-container-title  h2 {
  font-weight: bold;
  font-size: 41px;
  line-height: 120%;
  letter-spacing: 0.2px;
  color: #002F34;
}
.rec-slider-container{
  margin: 0 !important;
}
.carousel-card-link:focus {
  outline: none!important;
}
.carousel-card-link:hover {
  text-decoration: none;
}
.mainCarousel .tXlBS {
  width: 100% !important;
  margin-right: 22px;
}
.article-cards {
  padding: 1rem 0!important;
  background-color: #fff!important;
}
@media only screen and (min-width: 801px) {
  .article-cards .rec-item-wrapper {
    width: 220px!important;
  }
}
@media only screen and (max-width: 800px) {
  .mainCarousel .tXlBS {
    margin-right: 0;
    justify-content: flex-start;
  }
  .cards-container {
    padding: 6.5% 0 6.5% 6.5%!important;
  }
  .card-container-title  h2 {
    font-size: 24px;
  }
  .card-container-slide-icons {
    display: none!important;
  }
}