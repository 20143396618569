.article-carousel {
    position: relative;

    &__buttons {
        position: absolute;
        top: calc(50% - 24px);
        left: -75px;
        width: calc(100% + 150px);
        z-index: 100;

        @media (max-width: 576px) {
            position: initial;
            width: 100%;
        }
    }
}
.img-responsive {
    height: auto;
    object-fit: cover;
    max-width: 100%;
}