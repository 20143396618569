.card {
  width: 180px;
  height: 222px;
  border: none !important;
  border-radius: 1rem !important;
  cursor: pointer;
}
.card-body {
  background: #002f34;
  border-radius: 0 0 1rem 1rem;
  padding: 1rem!important;
}
.card-title {
  font-weight: bold;
  font-size: 16px;
  line-height: 120%;
  color: #fff;
}
.card__img {
  border-radius: 1rem 1rem 0 0!important;
  width: 180px!important;
  height: 110px!important;
}
@media only screen and (max-width: 800px) {
  .card {
    width: 156px!important;
    margin-right: 15px!important;
    margin-bottom: 0!important;
  }
  .card-title {
    font-size: 14px;
  }
  .card__img {
    width: 156px!important;
    height: 92px!important;
  }
}
