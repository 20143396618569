textarea:focus, input:focus{
  outline: none;
}
.search-input-container {
  width: 75%;
}
.input-style{
  height: 45px;
  border: none;
  background-color: transparent; 
  color: #BABABA;
}

.input-container{
  border: 1px solid #BABABA;
  border-radius: 10px;
  background-color: white
}

.input-button{
  padding-left: 5rem;
  padding-right: 5rem; 
  line-height: 3; 
  border-radius: 10px; 
  color: #BABABA; 
  background-color: #002F34; 
  border: 1px solid #BABABA
}


.suggestions-container{
  padding: 25px; 
  background-color: white;
  border-radius: 10px;
  border: 1px solid #BABABA; 
  top: 55px;
  z-index: 1;
  width: 75%
}

.suggestions{
  background-color: #3C74EC;
  border-radius: 15px;
  color: white;
  border: none;
}

@media only screen and (max-width: 800px) {
  .search-button {
    display: none;
  }
  .search-input-container {
    width: 100%;
  }
  .search-input-padding-container {
    padding: 0 6.5%
  }
  .suggestions-container {
    width: 87%;
    border: 0;
  }
}
