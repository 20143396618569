.articleText-container {
  margin: 32px 0;
}
.articleText {
  font-family: "SF Pro Display";
  font-weight: normal;
  font-size: 18px;
  line-height: 140%;
  letter-spacing: 0.2px;
  color: #000000;
  white-space: pre-line;
}
@font-face {
  font-family: "SF Pro Display";
  src: url("../../assets/fonts/SFProDisplay-Bold.eot");
  src: local("SF Pro Display Bold"), local("SFProDisplay-Bold"),
    url("../../assets/fonts/SFProDisplay-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/fonts/SFProDisplay-Bold.woff2") format("woff2"),
    url("../../assets/fonts/SFProDisplay-Bold.woff") format("woff"),
    url("../../assets/fonts/SFProDisplay-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
