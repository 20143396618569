@media (min-width: 1200px) {
    .articlePage-container .container {
        max-width: 50vw!important;
    }
    .articlePage-container .articleDesc-container .title_img_container{
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
        max-width: 50vw!important;
    }
    .articleTitle-text  {
        margin-top: 3rem;
        width: 75%;
    }
    .article-back-img {
        left: 2rem;
        top: 2rem;
    }
    .articleTitle-bg-img {
        width: 100%;
        height: 482px;
        object-fit: cover;
    }
}