$categoryLinkColor: #000000;

.category {
  &__container {
    padding: 50px 0 120px;
    @media (max-width: 576px) {
      padding: 20px 0 25px;
    }
  }
  &__link {
    color: $categoryLinkColor;
    &:hover {
      text-decoration: none;
    }
  }
}

.search-layout {
  width: 100%;
  padding-left: 13rem;
  background-color: #EEF5F5
}

.padding-lr{
  padding-right: 13rem;
  padding-left: 13rem
}
@media only screen and (min-width: 1200px) and (max-width: 1439px) {
  .padding-lr {
    padding-left: 8rem;
    padding-right: 8rem;
  }
  .search-layout{
    padding-left: 8rem;
  }
}
@media only screen and (min-width: 801px) and (max-width: 1199px) {
  .padding-lr {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .search-layout{
    padding-left: 4rem;
    padding-right: 4rem;
  }
}
@media only screen and (max-width: 800px) {
  .search-layout {
    padding: 0 2%;
  }
  .padding-lr{
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
