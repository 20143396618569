.articleList-container {
    border-radius: 30px;
    background-color: #C8DCFF;
    padding: 30px 36px;
    margin-bottom: 40px;
    margin-top: 30px;
}
.articleList-text {
    font-family: 'SF Pro Display', serif;
    font-size: 18px;
    line-height: 140%;
    letter-spacing: 0.2px;
    color: #002F34;
}
.articleList-text-container {
    margin-top: 24px;
}
.articleList-title {
    font-family: 'SF Pro Display', serif;
    font-weight: bold;
    font-size: 26px;
    line-height: 120%;
    letter-spacing: 0.2px;
    color: #002F34;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('../../assets/fonts/SFProDisplay-Bold.eot');
    src: local('SF Pro Display Bold'), local('SFProDisplay-Bold'),
    url('../../assets/fonts/SFProDisplay-Bold.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/SFProDisplay-Bold.woff2') format('woff2'),
    url('../../assets/fonts/SFProDisplay-Bold.woff') format('woff'),
    url('../../assets/fonts/SFProDisplay-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@media only screen and (max-width: 800px) {
    .articleList-title {
        font-size: 24px;
    }
    .articleList-container {
        margin-top: 30px;
    }
}