.diapazonPrice-container {
  background: #eef5f5;
  padding: 30px 24px;
  margin-bottom: 30px;
}
.diapazonPrice-title {
  font-family: "SF Pro Display";
  font-weight: bold;
  font-size: 26px;
  line-height: 120%;
  letter-spacing: 0.2px;
  color: #09322e;
  margin-bottom: 24px;
}
.diapazonPrice-text {
  margin-top: 18px;
  font-family: SF Pro Display;
  font-size: 18px;
  line-height: 140%;
  letter-spacing: 0.2px;
  color: #09322e;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("../../assets/fonts/SFProDisplay-Bold.eot");
  src: local("SF Pro Display Bold"), local("SFProDisplay-Bold"),
    url("../../assets/fonts/SFProDisplay-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/fonts/SFProDisplay-Bold.woff2") format("woff2"),
    url("../../assets/fonts/SFProDisplay-Bold.woff") format("woff"),
    url("../../assets/fonts/SFProDisplay-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@media only screen and (max-width: 800px) {

  .diapazonPrice-title {
    font-size: 24px!important;
  }
}
