
.bg{
  background-image: url("../../assets/images/logos/bg.svg");
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  padding-left: 13rem;
  padding-right: 13rem;
}
.bg-carousel-container{
  padding-bottom: 20vh;
}
.button-style{
  border-radius: 30px;
  padding-right: 30px;
  padding-top: 20px;
  padding-left: 30px;
  padding-bottom: 20px;
  font-weight: 500;
  font-family: "SF Pro Display";
  white-space: "nowrap";
  min-width: 186px;
  /* background-color: white;
  color: black;
  overflow: hidden; */
}

.text-title{
  font-size: 42px;
  color: white; 
  font-family: 'SF Pro Display';
  padding-left: 22px; 
  padding-top: 38px; 
  font-weight: 600;
  padding-bottom: 20px;
}
.decr {
  font-size: 3.15rem;
  color: #FFFFFF;
  width: 75%;
}
.desc{
  font-size: 42px;
  color: white;
  width: 55%;
  line-height: 50px;
  font-weight: 500;
}

@media only screen and (max-width: 800px) {
  .bg-carousel-container {
    justify-content: space-around;
  }
  .bg {
    padding: 0!important;
    background-size: 100% 100%;
  }
  .decr {
    padding-top: 28px!important;
    padding-left: 24px!important;
    padding-right: 24px!important;
    color: #fff;
    font-size: 20px;
    width: 100%;
  }
  .text-title {
    display: flex;
    justify-content: center;
    padding-top: 8%;
    font-size: 29px;
    font-weight: 700;
    width: 100%;
  }
  .button-style {
    padding: 16px!important;
  }
}


.overflow-scroll {
  overflow: auto;
  /* this will hide the scrollbar in mozilla based browsers */
  overflow: -moz-scrollbars-none;
  /* this will hide the scrollbar in internet explorers */
  -ms-overflow-style: none;
}

@media only screen and (min-width: 1200px) and (max-width: 1441px) {
  .bg {
    height: 100vh;
  }
}