$cardBackgroundColor: #F6F6F6;
$cardTextColor: #09322E;
$boxShadow: 0 0 10px rgba(0, 0, 0, 0.15);

$cardHeight: 100%;
$cardHeaderHeight: 188px;
$cardHeaderMobileHeight: 120px;
$cardBodyHeight: calc(#{$cardHeight} - #{$cardHeaderHeight});
$mobileAdaptationBreakPoint: 576px;

$cardFontFamily: "SF Pro Display";
$borderRadius: 15px;

.category-card {
  height: $cardHeight;
  font-family: $cardFontFamily;

  border-radius: $borderRadius;
  box-shadow: $boxShadow;

  transition: 0.5s transform ease;
  &:hover {
    transform: translateY(-8px);
  }

  &__header {
    border-radius: $borderRadius $borderRadius 0 0;
    &--background {
      height: $cardHeaderHeight;
      width: 100%;
      border-radius: inherit;
      object-fit: cover;

      @media (max-width: $mobileAdaptationBreakPoint) {
        height: $cardHeaderMobileHeight;
      }
    }
  }

  &__body {
    height: $cardBodyHeight;
    color: $cardTextColor;
    line-height: 120%;

    &--title {
      font-size: 18px;
      font-weight: bold;

      @media (max-width: $mobileAdaptationBreakPoint) {
        font-size: 16px;
      }
    }
    &--description {
      font-size: 16px;
      font-weight: normal;

      @media (max-width: $mobileAdaptationBreakPoint) {
        font-size: 14px;
      }
    }
    &__html {
      p {
        margin: 0;
      }
    }
  }
}