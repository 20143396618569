.header {
  background-color: #002F34;
}

.pd-left {
  padding-left: 12rem
}

.stick{
  position: sticky;
  top: 0;
  z-index: 2;
}
@media only screen and (max-width: 800px) {
  .pd-left {
    padding-left: 7%!important;
  }
  .stick {
    z-index: 0;
  }
}

